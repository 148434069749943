<template>
    <v-dialog v-model="isFeesModalOpen"  max-width="600">
        <v-card>
            <v-card-title class="py-2 pr-3 justify-space-between">
                <h6 class="text-uppercase">Campaign Fees</h6>
                <v-btn icon @click="dismiss">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-subtitle class="mt-3">
                <h4>{{ campaign.name }}</h4>
            </v-card-subtitle>


            <v-form ref="form" v-model="valid" lazy-validation>
                <v-card-text>
                    <v-row class="flex-column">
                        <v-col>
                            <h4 class="my-3">Tag Client</h4>
                            <v-select v-model="tagged_client" required :items="clients" item-text="client_name"
                                return-object outlined dense append-icon="mdi-chevron-down" label="Select client"
                                hide-details="auto" :menu-props="{ bottom: true, offsetY: true }" clearable></v-select>
                        </v-col>

                        <v-col>
                            <h4 class="my-3">Platform Fee (%)</h4>
                            <v-text-field v-model="platform_fee" single-line outlined dense
                                hide-details="auto" clearable></v-text-field>
                        </v-col>

                        <v-col>
                            <h4 class="my-3">Agency Arbitrage Fee (%)</h4>
                            <v-text-field v-model="agency_arbitrage_fee" single-line outlined dense
                                hide-details="auto" clearable></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-form>

            <v-card-text>
                <v-btn type="submit" @click="submitForm" block depressed color="primary" class="mt-2">
                    Submit
                </v-btn>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
  
  
<script>

export default {
    props: {
        isFeesModalOpen: {
            type: Boolean,
            required: true,
        },
        campaign: {
            type: Object,
            required: true,
        },
        clients: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            valid: true,
            tagged_client: null,
            platform_fee: null,
            agency_arbitrage_fee: null,
        };
    },
    watch: {
        campaign() {
            this.tagged_client = this.campaign.client_id ? this.campaign.client_id : '';
            this.platform_fee = this.campaign.platform_fee ? this.campaign.platform_fee : '';
            this.agency_arbitrage_fee = this.campaign.agency_arbitrage_fee ? this.campaign.agency_arbitrage_fee : '';
        },
    },
    methods: {
        dismiss() {
            this.$emit("dismiss");
        },
        handleDrawerModelValueUpdate(value) {
            if (value === false) {
                this.$emit("dismiss");
            }
        },
        submitForm() {
            const isvalid = this.$refs.form.validate();

            if (isvalid) {
                let data = {
                    client_id:this.tagged_client.id ? this.tagged_client.id :'',
                    agency_arbitrage_fee: this.agency_arbitrage_fee,
                    platform_fee: this.platform_fee,

                };
                this.$emit("updateFacebookFees", data);
            }
            this.dismiss();
        },
    },
};
</script>
  