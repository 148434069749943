<template>
  <div class="mt-5">
    <v-btn
      v-if="!loading"
      small
      color="grey"
      outlined
      @click="$router.back()"
      class="mb-5"
    >
      <v-icon left> mdi-arrow-left </v-icon>
      Back
    </v-btn>
    <div v-else class="button"></div>
    <FacebookFees
      v-if="facebook_campaign"
      :isFeesModalOpen="feesDialog"
      :campaign="facebook_campaign"
      :clients="clients"
      @dismiss="closeModal($event)"
      @updateFacebookFees="updateFBCampaignFees($event)"
    />
    <ConfirmDelete
      :dialog="dialog"
      :item="facebook_campaign"
      @delete="deleteFBCampaign($event)"
      @dismiss="dismissFn($event)"
    />
    <v-row class="mt-3">
      <v-col>
        <h2 v-if="!loading && facebook_campaign">
          {{ facebook_campaign.name }}
        </h2>
        <div v-else class="heading"></div>
      </v-col>
      <div class="d-flex align-center ml-5">
        <v-menu v-if="!loading" bottom offset-y open-on-hover>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark depressed v-bind="attrs" v-on="on">
              Actions <v-icon right>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <!-- <v-list-item>
              <v-list-item-title><v-icon small left>mdi-download</v-icon> Download
                Report</v-list-item-title>
            </v-list-item> -->
            <v-list-item
              @click="pauseFacebookCampaign"
              v-if="
                facebook_campaign.status === 'ACTIVE' ||
                facebook_campaign.status === ''
              "
            >
              <v-list-item-title
                ><v-icon small left>mdi-pause</v-icon> Pause
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              @click="activateFacebookCampaign"
              v-if="
                facebook_campaign.status === 'PAUSED' ||
                facebook_campaign.status === ''
              "
            >
              <v-list-item-title
                ><v-icon small left>mdi-play</v-icon> Activate Campaign
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              @click="
                $router.push({
                  name: 'update-facebook-campaign',
                  params: { id: campaignId },
                })
              "
            >
              <v-list-item-title
                ><v-icon small left>mdi-pencil</v-icon> Edit
                Campaign</v-list-item-title
              >
            </v-list-item>
            <v-list-item @click="openFeesDrawer" v-if="superAdmin">
              <v-list-item-title
                ><v-icon small left>mdi-currency-usd</v-icon> Edit
                Fees</v-list-item-title
              >
            </v-list-item>
            <v-list-item @click="openModal">
              <v-list-item-title
                ><v-icon small left>mdi-trash-can</v-icon>
                Delete</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
        <div v-else class="actions"></div>
      </div>
    </v-row>
    <v-row class="py-4">
      <span class="overline ml-2">
        <v-chip
          v-if="!loading && facebook_campaign.status === null"
          color="grey lighten-5"
          text-color="grey"
          class="font-weight-bold"
          small
        >
          <v-icon x-small left>mdi-circle</v-icon>Incomplete
        </v-chip>
        <div v-else-if="loading" class="actions"></div>
        <!-- <v-skeleton-loader v-else-if="loading" type="button" width="100%" height="100%"></v-skeleton-loader> -->
        <v-chip
          v-else-if="!loading && facebook_campaign.status === 'ACTIVE'"
          color="green lighten-5"
          text-color="success"
          class="font-weight-bold"
          small
        >
          <v-icon x-small left>mdi-circle</v-icon>{{ facebook_campaign.status }}
        </v-chip>
        <div v-else-if="loading" class="actions"></div>
        <v-chip
          v-else-if="!loading && facebook_campaign.status === 'PAUSED'"
          color="grey lighten-5"
          text-color="grey"
          class="font-weight-bold"
          small
        >
          <v-icon x-small left>mdi-circle</v-icon>{{ facebook_campaign.status }}
        </v-chip>
        <div v-else-if="loading" class="actions"></div>
        <v-chip
          v-else
          color="red lighten-5"
          text-color="error"
          class="font-weight-bold"
          small
        >
          <v-icon x-small left>mdi-circle</v-icon>{{ facebook_campaign.status }}
        </v-chip>
      </span>
      <span class="overline ml-4">
        <template v-if="!loading">
          {{
            new Date(facebook_campaign.start_time).toDateString() +
            "-" +
            (facebook_campaign.effective_status !== "ACTIVE"
              ? new Date(facebook_campaign.updated_time).toDateString()
              : "NOW")
          }}
        </template>
        <div v-else class="button"></div>
      </span>
    </v-row>
    <section>
      <v-row>
        <v-col>
          <v-card outlined>
            <v-card-title class="d-flex align-center py-2">
              <div class="d-flex">
                <h5 v-if="!loading">Campaign Overview</h5>
                <v-skeleton-loader
                  v-else
                  type="text"
                  width="150px"
                  height="24px"
                ></v-skeleton-loader>
              </div>
            </v-card-title>
            <v-card-text
              ><v-row class="mb-2">
                <v-col lg="3" sm="6" cols="12">
                  <v-card flat color="grey lighten-5">
                    <v-card-subtitle class="display-1">
                      <template v-if="!loading">{{
                        capitalizeFirstLetter(facebook_campaign.objective)
                      }}</template>
                      <v-skeleton-loader
                        v-else
                        type="text"
                        width="150px"
                        height="24px"
                      ></v-skeleton-loader>
                    </v-card-subtitle>
                    <v-card-subtitle class="overline grey--text lighten-5">
                      <template v-if="!loading">OBJECTIVE</template>
                      <v-skeleton-loader
                        v-else
                        type="text"
                        width="150px"
                        height="24px"
                      ></v-skeleton-loader>
                    </v-card-subtitle>
                  </v-card>
                </v-col>
                <v-col lg="3" sm="6" cols="12">
                  <v-card flat color="grey lighten-5">
                    <v-card-subtitle class="display-1">
                      <template v-if="!loading">{{
                        capitalizeFirstLetter(facebook_campaign.buying_type)
                      }}</template>
                      <v-skeleton-loader
                        v-else
                        type="text"
                        width="150px"
                        height="24px"
                      ></v-skeleton-loader>
                    </v-card-subtitle>
                    <v-card-subtitle class="overline grey--text lighten-5">
                      <template v-if="!loading">BUYING TYPE</template>
                      <v-skeleton-loader
                        v-else
                        type="text"
                        width="150px"
                        height="24px"
                      ></v-skeleton-loader>
                    </v-card-subtitle>
                  </v-card>
                </v-col>
                <v-col lg="3" sm="6" cols="12">
                  <v-card flat color="grey lighten-5">
                    <v-card-subtitle class="display-1">
                      <template v-if="!loading">
                        {{
                          capitalizeFirstLetter(
                            facebook_campaign.special_ad_category
                          )
                        }}</template
                      >
                      <v-skeleton-loader
                        v-else
                        type="text"
                        width="150px"
                        height="24px"
                      ></v-skeleton-loader>
                    </v-card-subtitle>
                    <v-card-subtitle class="overline grey--text lighten-5">
                      <template v-if="!loading">SPECIAL AD CATEGORY</template>
                      <v-skeleton-loader
                        v-else
                        type="text"
                        width="150px"
                        height="24px"
                      ></v-skeleton-loader> </v-card-subtitle
                  ></v-card>
                </v-col>
                <v-col lg="3" sm="6" cols="12">
                  <v-card flat color="grey lighten-5">
                    <v-card-subtitle class="display-1">
                      <template v-if="!loading">{{
                        parseFloat(
                          facebook_campaign.budget_remaining
                        ).toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })
                      }}</template>
                      <v-skeleton-loader
                        v-else
                        type="text"
                        width="150px"
                        height="24px"
                      ></v-skeleton-loader>
                    </v-card-subtitle>
                    <v-card-subtitle class="overline grey--text lighten-5">
                      <template v-if="!loading">BUDGET REMAINING</template>
                      <v-skeleton-loader
                        v-else
                        type="text"
                        width="150px"
                        height="24px"
                      ></v-skeleton-loader>
                    </v-card-subtitle>
                  </v-card>
                </v-col> </v-row
            ></v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </section>
    <v-row class="d-flex align-center mb-1">
      <v-col>
        <h4 v-if="!loading">Show statistics for selected Date Range</h4>
        <v-skeleton-loader
          v-else
          type="heading"
          width="60%"
          height="100%"
        ></v-skeleton-loader>
      </v-col>
      <v-col cols="4" class="pl-0">
        <v-menu
          v-if="!loading"
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateRangeText"
              label="Period"
              readonly
              v-bind="attrs"
              v-on="on"
              class="shrink"
              outlined
              prepend-inner-icon="mdi-calendar"
              dense
              hide-details
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dates"
            scrollable
            range
            no-title
            @change="selectDateRange()"
            :min="this.startDate"
            :max="this.endDate"
          >
          </v-date-picker>
        </v-menu>
        <div v-else class="select-boxes"></div>
      </v-col>
    </v-row>

    <v-divider></v-divider>
    <v-expand-x-transition>
      <v-col v-if="facebook_campaign_insights">
        <v-row
          class="mt-3"
          style="
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
          "
        >
          <v-col>
            <total-card
              :title="reach_card_title"
              v-if="facebook_campaign_insights"
              :loading="loading"
              :stats="facebook_campaign_insights.reach"
              :graphdata="
                facebook_time_based_campaign_insights.map((item) =>
                  parseInt(item.reach)
                )
              "
              color="#ffb01a"
            />
          </v-col>
          <v-col>
            <total-card
              :title="cost_card_title"
              v-if="facebook_campaign_insights"
              :stats="facebook_campaign_insights.spend"
              :currency="facebook_campaign_insights.account_currency"
              :graphdata="
                facebook_time_based_campaign_insights.map((item) =>
                  parseFloat(item.spend)
                )
              "
              color="#008ffb"
              :loading="loading"
            />
          </v-col>
          <v-col>
            <total-card
              :title="clicks_card_title"
              v-if="facebook_campaign_insights"
              :stats="facebook_campaign_insights.clicks"
              :graphdata="
                facebook_time_based_campaign_insights.map((item) =>
                  parseInt(item.clicks)
                )
              "
              color="#50e7a6"
              :loading="loading"
            />
          </v-col>
          <v-col>
            <total-card
              :title="impressions_card_title"
              v-if="facebook_campaign_insights"
              :stats="facebook_campaign_insights.impressions"
              :graphdata="
                facebook_time_based_campaign_insights.map((item) =>
                  parseInt(item.impressions)
                )
              "
              :loading="loading"
            />
          </v-col>
          <v-col>
            <total-card
              :title="link_clicks_card_title"
              v-if="
                facebook_campaign_insights && facebook_campaign_insights.actions
              "
              :stats="
                facebook_campaign_insights.actions.find(
                  (item) => item.action_type === 'link_click'
                ).value
              "
              :graphdata="
                facebook_time_based_campaign_insights.map((item) =>
                  parseInt(item.inline_link_clicks)
                )
              "
              :loading="loading"
            />
          </v-col>
        </v-row>

        <v-row
          class="mt-3"
          style="
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
          "
        >
          <v-col>
            <total-card
              :title="ctr_card_title"
              v-if="facebook_campaign_insights"
              :stats="facebook_campaign_insights.ctr"
              :percentage="percentage"
              :graphdata="
                facebook_time_based_campaign_insights.map((item) =>
                  parseFloat(item.ctr)
                )
              "
              color="#ffb01a"
              :loading="loading"
            />
          </v-col>
          <v-col>
            <total-card
              :title="cpc_card_title"
              v-if="facebook_campaign_insights"
              :stats="facebook_campaign_insights.cpc"
              :currency="facebook_campaign_insights.account_currency"
              :graphdata="
                facebook_time_based_campaign_insights.map((item) =>
                  parseFloat(item.cpc)
                )
              "
              color="#008ffb"
              :loading="loading"
            />
          </v-col>
          <v-col>
            <total-card
              :title="cpm_card_title"
              v-if="facebook_campaign_insights"
              :stats="facebook_campaign_insights.cpm"
              :currency="facebook_campaign_insights.account_currency"
              :graphdata="
                facebook_time_based_campaign_insights.map((item) =>
                  parseFloat(item.cpm)
                )
              "
              color="#50e7a6"
              :loading="loading"
            />
          </v-col>
          <v-col>
            <total-card
              :title="cpp_card_title"
              v-if="facebook_campaign_insights"
              :stats="facebook_campaign_insights.cpp"
              :currency="facebook_campaign_insights.account_currency"
              :graphdata="
                facebook_time_based_campaign_insights.map((item) =>
                  parseFloat(item.cpp)
                )
              "
              :loading="loading"
            />
          </v-col>
          <v-col>
            <total-card
              :title="link_clicks_cost_card_title"
              v-if="facebook_campaign_insights"
              :stats="facebook_campaign_insights.cost_per_inline_link_click"
              :currency="facebook_campaign_insights.account_currency"
              :graphdata="
                facebook_time_based_campaign_insights.map((item) =>
                  parseFloat(item.cost_per_inline_link_click)
                )
              "
              :loading="loading"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-expand-x-transition>

    <v-row class="d-flex align-center my-1 mt-5">
      <v-col>
        <h4 v-if="!loading">Trends</h4>
        <v-skeleton-loader
          v-else
          type="heading"
          width="60%"
          height="100%"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row class="mt-3">
      <v-col cols="12" sm="6">
        <general-card v-if="!loading">
          <v-col cols="6">
            <h4 class="ml-4">Impressions</h4>
          </v-col>
          <apexchart
            ref="impressionschart"
            type="line"
            :options="impressions_chart_options"
            :series="impressions_chart_options.series"
            height="400"
          />
        </general-card>
        <div v-else class="custom-skeleton"></div>
      </v-col>
      <v-col cols="12" sm="6">
        <general-card v-if="!loading">
          <v-col cols="6">
            <h4 class="ml-4">Clicks/CTR</h4>
          </v-col>
          <apexchart
            ref="clickchart"
            type="line"
            :options="clicks_chart_options"
            :series="clicks_chart_options.series"
            height="400"
          />
        </general-card>
        <div v-else class="custom-skeleton"></div>
      </v-col>
    </v-row>
    <v-row class="d-flex align-center my-1 mt-2">
      <v-col>
        <h4 v-if="!loading">Demographics</h4>
        <v-skeleton-loader
          v-else
          type="heading"
          width="60%"
          height="100%"
        ></v-skeleton-loader>
      </v-col>
      <v-col cols="3">
        <v-select
          v-if="!loading"
          class="shrink pa-5"
          outlined
          dense
          label="Unit"
          @change="selectSeries()"
          v-model="select_series"
          :items="selectCharts"
          hide-details
          :menu-props="{ bottom: true, offsetY: true }"
        ></v-select>
        <div v-else class="select-boxes"></div>
      </v-col>
      <v-col cols="3">
        <v-select
          v-if="!loading"
          class="shrink pa-5"
          outlined
          dense
          label="Metrics"
          @change="selectSeries()"
          v-model="select_metrics"
          :items="metrics"
          hide-details
          :menu-props="{ bottom: true, offsetY: true }"
        ></v-select>
        <div v-else class="select-boxes"></div>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row class="mt-3">
      <v-col>
        <general-card v-if="!loading" class="fill-height pa-5">
          <v-row>
            <v-col>
              <apexchart
                ref="ageGenderChart"
                type="bar"
                :options="ageGenderOptions"
                :series="ageGenderOptions.series"
                height="400"
              />
            </v-col>
          </v-row>
        </general-card>
        <div v-else class="custom-skeleton"></div>
      </v-col>
    </v-row>
    <v-row class="d-flex align-center my-1 mt-2">
      <v-col>
        <h4 v-if="!loading">Breakdowns</h4>
        <v-skeleton-loader
          v-else
          type="heading"
          width="60%"
          height="100%"
        ></v-skeleton-loader>
      </v-col>
      <v-col cols="3">
        <v-select
          v-if="!loading"
          class="shrink pa-5"
          outlined
          dense
          label="Unit"
          @change="selectBreakdowns()"
          v-model="select_breakdowns"
          :items="breakdowns"
          hide-details
          :menu-props="{ bottom: true, offsetY: true }"
        ></v-select>
        <div v-else class="select-boxes"></div>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row class="mt-3">
      <v-col cols="4">
        <general-card v-if="!loading" class="fill-height">
          <v-col class="d-flex flex-column align-center">
            <h4 class="ml-4">Action Device Interaction Rates</h4>
          </v-col>
          <apexchart
            ref="pieChart"
            type="pie"
            :options="pieChartOptions"
            :series="pieChartOptions.series"
            width="100%"
            height="400"
          />
        </general-card>
        <div v-else class="custom-skeleton"></div>
      </v-col>
      <v-col>
        <general-card v-if="!loading" class="fill-height pa-5">
          <v-row align-center justify-center>
            <v-col cols="6" v-if="doublebarchartOptions.series != []">
              <v-col class="d-flex align-center">
                <h4 class="ml-4">Impressions Actions Comparison</h4>
              </v-col>
              <apexchart
                ref="doublebarchart"
                type="bar"
                :options="doublebarchartOptions.options"
                :series="doublebarchartOptions.series"
                height="400"
                width="100%"
              />
            </v-col>
            <v-col cols="6">
              <v-col class="d-flex align-center">
                <h4 class="ml-4">Breakdowns Interaction Rates</h4>
              </v-col>
              <apexchart
                ref="chart"
                height="400"
                width="100%"
                :options="donutChartOptions"
                :series="donutChartOptions.series"
              />
            </v-col>
          </v-row>
        </general-card>
        <div v-else class="custom-skeleton"></div>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <general-card v-if="!loading" class="fill-height pa-5">
          <v-col class="d-flex align-center">
            <h4 class="ml-4">Best Actions</h4>
          </v-col>
          <v-data-table
            :headers="headers"
            :items="bestActions"
            :sort-by="['value']"
            :sort-desc="true"
            :items-per-page="5"
          >
            <template v-slot:item.action="{ item }">
              {{ formatString(item.action) }}
            </template>
          </v-data-table>
        </general-card>
        <div v-else class="custom-skeleton"></div>
      </v-col>
      <v-col>
        <general-card v-if="!loading" class="fill-height pa-5">
          <v-col class="d-flex align-center">
            <h4 class="ml-4">Least Actions</h4>
          </v-col>
          <v-data-table
            :headers="headers"
            :items="leastActions"
            :sort-by="['value']"
            :sort-desc="true"
            :items-per-page="5"
          >
            <template v-slot:item.action="{ item }">
              {{ formatString(item.action) }}
            </template>
          </v-data-table>
        </general-card>
        <div v-else class="custom-skeleton"></div>
      </v-col>
    </v-row>
    <v-row
      ><v-col
        ><v-card>
          <v-card-title>Distribution</v-card-title><v-divider></v-divider>
          <div
            :class="{ 'custom-skeleton': loading }"
            id="basemap_id"
            ref="basemap"
          ></div>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <general-card v-if="!loading">
          <h4 class="ma-4 pt-1">Adsets</h4>
          <v-divider></v-divider>
          <adsets-table v-if="facebook_campaign_adsets" :adsets="facebook_campaign_adsets" />
        </general-card>
        <div v-else class="custom-skeleton"></div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import config from "@/apex-config";
// import MainTopbar from "../../../components/Layouts/Topbars/MainTopbar.vue";
import TotalCard from "../../../components/Layouts/Cards/TotalCard.vue";
import ConfirmDelete from "../../../components/ConfirmDelete.vue";
import GeneralCard from "../../../components/Layouts/Cards/GeneralCard.vue";
import FacebookFees from "../../../components/Facebook/FacebookFees.vue";
import AdsetsTable from "../../../components/Tables/AdsetsTable.vue";

import VectorMap from "jsvectormap";
import "jsvectormap/dist/maps/world";

export default {
  components: {
    // MainTopbar,
    FacebookFees,
    TotalCard,
    GeneralCard,
    ConfirmDelete,
    AdsetsTable
  },
  data: () => ({
    config,
    map: {},
    sideWidgetData: null,
    reach_card_title: "TOTAL REACH",
    cost_card_title: "TOTAL SPENT",
    clicks_card_title: "TOTAL CLICKS",
    impressions_card_title: "TOTAL IMPRESSIONS",
    cpc_card_title: "AVG. CPC",
    ctr_card_title: "TOTAL CTR",
    cpm_card_title: "AVERAGE CPM",
    cpp_card_title: "AVERAGE CPP",
    link_clicks_card_title: "LINK CLICKS",
    link_clicks_cost_card_title: "COST PER LINK CLICK",
    percentage: "%",
    error: null,
    menuFrom: false,
    menuTo: false,
    feesDialog: false,
    dialog: false,

    //Chart Inits
    campaigns_clicks_chart_data: null,
    campaigns_impressions_chart_data: null,

    //Request data
    adAccountId: "",
    campaignId: "",
    date_preset: "maximum",
    time_increment: 1,
    age_breakdown: "age",

    //Age Chart
    age_chart_data: null,
    age_chart_options: {
      maintainAspectRatio: false,
      responsive: true,
      scale: {
        display: false,
      },
    },

    selectedFromDate: "",
    selectedToDate: "",
    btns: ["Add To Favorites", "Stop", "Pause", "Clone", "Edit", "Delete"],
    ageReach: [
      ["#4F92ED", "16 - 25", 2.2, "up"],
      ["#3371E2", "26 - 35", 0.7, "down"],
      ["#70E09B", "36 - 45", 5.2, "up"],
      ["#F8C76C", "46 - 55", 0.2, "down"],
      ["#E9559A", "Over 55", 1.5, "down"],
    ],
    deviceReach: [
      ["#4F92ED", "Mobile Phone", 2.2, "up"],
      ["#3371E2", "Tablet", 0.7, "down"],
      ["#70E09B", "PC", 5.2, "up"],
      ["#F8C76C", "Smart TV", 0.2, "down"],
      ["#E9559A", "Smart Watch", 1.5, "down"],
    ],
    genderReach: [
      ["#4F92ED", "Male", 2.2, "up"],
      ["#3371E2", "Female", 0.7, "down"],
    ],
    selectCharts: ["All", "Male", "Female"],
    metrics: ["Impressions", "Reach", "Spend"],
    select_series: "All",
    select_metrics: "Impressions",
    breakdowns: [
      "Country",
      "Region",
      "Publisher Platform",
      "Impression Device",
    ],
    select_breakdowns: "Country",
    apexLoading: false,
    dates: [],
    menu: false,
    startDate: null,
    endDate: null,
    impressions_chart_options: {
      series: [],
      chart: {
        height: 350,
        type: "line",
        toolbar: {
          show: false,
        },
      },
      markers: {
        size: 3,
        style: "hollow",
        hover: {
          sizeOffset: 4,
        },
      },
      xaxis: {
        type: "datetime",
        categories: [],
      },
      noData: {
        text: "Fetching Data...",
        align: "center",
        verticalAlign: "middle",
      },
      colors: [config.light.primary, config.light.error],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 2,
        curve: "straight",
      },
      legend: {
        show: true,
        showForNullSeries: false,
        position: "top",
        horizontalAlign: "center",
      },
      yaxis: {},
      fill: {
        type: "solid",
        opacity: 0.9,
      },
      grid: {
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
    },
    clicks_chart_options: {
      series: [],
      chart: {
        height: 350,
        type: "line",
        toolbar: {
          show: false,
        },
      },
      markers: {
        size: 3,
        style: "hollow",
        hover: {
          sizeOffset: 4,
        },
      },
      xaxis: {
        type: "datetime",
        categories: [],
      },
      noData: {
        text: "Fetching Data...",
        align: "center",
        verticalAlign: "middle",
      },
      colors: [config.light.primary, config.light.error],
      stroke: {
        width: [2, 2],
        dashArray: [0, 0],
        curve: ["straight", "straight"],
      },
      legend: {
        position: "top",
      },
      yaxis: {
        labels: {
          formatter: function (value) {
            return value.toFixed(2);
          },
        },
      },
      grid: {
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
    },
    donutChartOptions: {
      series: [],
      chart: {
        type: "donut",
        height: "400",
      },
      labels: [],
      responsive: [
        {
          breakpoint: 10000,
          options: {
            chart: {
              height: "400",
              width: "100%",
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
      colors: [
        "#509FD4",
        "#50e7a6",
        "#ffb01a",
        "#80CBC4",
        "#ffb01a",
        "#008ffb",
        "#50e7a6",
        "#FFC300",
        "#C70039",
        "#900C3F",
        "#581845",
      ],
      noData: {
        text: "Fetching Data...",
        align: "center",
        verticalAlign: "middle",
      },
    },
    doublebarchartOptions: {
      series: [],
      options: {
        chart: {
          type: "bar",
          height: "400",
          // zoom: {
          //   enabled: true,
          //   type: "x",
          // },
          toolbar: {
            show: false,
          },
        },
        responsive: [
          {
            breakpoint: 10000,
            options: {
              plotOptions: {
                bar: {
                  horizontal: false,
                  columnWidth: "70px",
                  endingShape: "rounded",
                },
              },
            },
          },
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "70px",
            endingShape: "rounded",
          },
        },
        colors: [
          "#509FD4",
          "#50e7a6",
          "#ffb01a",
          "#80CBC4",
          "#ffb01a",
          "#008ffb",
          "#50e7a6",
          "#FFC300",
          "#C70039",
          "#900C3F",
          "#581845",
        ],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          title: {
            text: "Impressions|Actions",
          },
          // labels: {
          //   formatter: function (value) {
          //     return value.toLocaleString();
          //   },
          // },
        },
        fill: {
          opacity: 1,
        },
        noData: {
          text: "Fetching Data...",
          align: "center",
          verticalAlign: "middle",
        },
      },
      grid: {
        // xaxis: {
        //   lines: {
        //     show: true,
        //   },
        // },
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
    },
    pieChartOptions: {
      series: [],
      chart: {
        type: "pie",
        height: "400",
      },
      responsive: [
        {
          breakpoint: 10000,
          options: {
            chart: {
              height: "400",
              width: "100%",
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
      labels: [],
      colors: [
        "#509FD4",
        "#50e7a6",
        "#ffb01a",
        "#80CBC4",
        "#ffb01a",
        "#008ffb",
        "#50e7a6",
        "#FFC300",
        "#C70039",
        "#900C3F",
        "#581845",
      ],
      noData: {
        text: "Fetching Data...",
        align: "center",
        verticalAlign: "middle",
      },
    },
    headers: [
      { text: "Action", value: "action" },
      { text: "Value", value: "value" },
    ],
    actions: "",
    ageGenderOptions: {
      series: [],
      options: {
        chart: {
          type: "bar",
          height: 350,
          zoom: {
            enabled: true,
            type: "x",
          },
          toolbar: {
            show: false,
          },
        },
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "60px",
        },
      },
      colors: [
        "#509FD4",
        "#50e7a6",
        "#ffb01a",
        "#80CBC4",
        "#ffb01a",
        "#008ffb",
        "#50e7a6",
        "#FFC300",
        "#C70039",
        "#900C3F",
        "#581845",
      ],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
      },
      xaxis: {
        categories: [
          // "13-17",
          // "18-24",
          // "25-34",
          // "35-44",
          // "45-54",
          // "55-64",
          // "65+",
          // "Unknown",
        ],
        title: {
          text: "",
        },
      },
      yaxis: {
        title: {
          text: "Impressions",
        },
        labels: {
          formatter: function (value) {
            return value.toLocaleString();
          },
        },
      },
      fill: {
        opacity: 1,
      },
      noData: {
        text: "Fetching Data...",
        align: "center",
        verticalAlign: "middle",
      },
    },
    genderGroups: ["male", "female", "unknown"],
    bestActions: [],
    leastActions: [],
    ageGender: [],
  }),

  created() {
    this.campaignId = this.$route.params.id;
    this.adAccountId = this.$route.params.account_id;
    this.getBreakdownInsights(),
      this.getStaticData(),
      this.getSingleFacebookCampaign(),
      this.getSingleFacebookCampaignInsights(),
      this.getTimeBasedSingleFacebookCampaignInsights(),
      this.readClients();
    this.getCampaignAdsets();
  },

  mounted() {
    this.startDate = this.facebook_campaign_insights
      ? this.facebook_campaign_insights.date_start
      : null;
    this.endDate = this.facebook_campaign_insights
      ? this.facebook_campaign_insights.date_stop
      : null;
    this.dates = [this.startDate, this.endDate];
  },
  methods: {
    async getCampaignAdsets() {
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("facebook/getFacebookAdsetsByCampaign", {
          adAccountId: this.adAccountId,
          campaignId: this.campaignId,
        });
        this.$store.commit("makingApiRequest", false);
      } catch (error) {
        this.error = error;
      }
    },
    openFeesDrawer() {
      this.feesDialog = true;
    },

    closeModal() {
      this.feesDialog = false;
    },

    dismissFn() {
      this.dialog = false;
    },

    async openModal() {
      this.dialog = !this.dialog;
    },

    async updateFBCampaignFees(data) {
      const combined_data = {
        campaign_id: this.$route.params.id,
        data: data,
      };
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch(
          "facebook/updateFacebookCampaignFees",
          combined_data
        );
        this.$store.commit("makingApiRequest", false);
        this.$notify({
          title: "Campaign Fees Updated successfully!",
          group: "success",
        });
      } catch (error) {
        this.error = error;
      }
    },

    async getStaticData() {
      try {
        await this.$store.dispatch("dsp/getStaticDspData");
      } catch (error) {
        this.error = error;
      }
    },
    async getSingleFacebookCampaign() {
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("facebook/getOneFacebookCampaign", {
          adAccountId: this.adAccountId,
          campaignId: this.campaignId,
        });
        this.$store.commit("makingApiRequest", false);
      } catch (error) {
        this.error = error;
      }
    },
    async getSingleFacebookCampaignInsights() {
      try {
        await this.$store.dispatch("facebook/getFacebookCampaignInsights", {
          accountId: this.adAccountId,
          campaignId: this.campaignId,
          date_preset: this.date_preset,
          time_increment: this.time_increment,
        });
      } catch (error) {
        this.error = error;
      }
    },

    async getTimeBasedSingleFacebookCampaignInsights() {
      try {
        await this.$store.dispatch(
          "facebook/getTimeBasedFacebookCampaignInsights",
          {
            accountId: this.adAccountId,
            campaignId: this.campaignId,
            date_preset: this.date_preset,
            time_increment: this.time_increment,
          }
        );
        this.getChartsData();
      } catch (error) {
        this.error = error;
      }
    },

    async getBreakdownInsights() {
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch(
          "facebook/getCombinedFacebookCampaignInsights",
          {
            adAccountId: this.adAccountId,
            campaignId: this.campaignId,
            date_preset: "maximum",
            breakdowns: [
              ["age"],
              ["gender"],
              ["country"],
              ["region"],
              ["publisher_platform"],
              ["impression_device"],
              ["device_platform"],
            ],
            action_breakdowns: [
              ["action_device"],
              ["action_carousel_card_id", "action_carousel_card_name"],
            ],
          }
        );
        await this.$nextTick();
        this.populateMap();
        this.getDonutChartInitialData();
        this.getPieChartInitialData();
        this.getDoublebarChartInitial();
        this.getGenderAgeGraphInitialData();
        this.$store.commit("makingApiRequest", false);
      } catch (error) {
        this.error = error;
      }
    },
    async populateMap() {
      const items = this.facebook_countries.map((country) => country.country);
      this.map = new VectorMap({
        selector: "#basemap_id",
        map: "world",
        selectedRegions: items,
        panOnDrag: false,
        zoomOnScroll: false,
        regionStyle: {
          selected: { fill: "orange" },
        },
        zoomButtons: false,
      });
    },
    capitalizeFirstLetter(string) {
      return string
        .replaceAll("_", " ")
        .toLowerCase()
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    },
    async getChartsData() {
      //Impressions Graph Data
      try {
        (this.impressions_chart_options.series = [
          {
            name: "Impressions",
            data: this.$store.state.facebook.facebook_time_based_campaign_insights.map(
              (impressions) => parseInt(impressions.impressions) / 1000
            ),
          },
        ]),
          (this.impressions_chart_options = {
            ...this.impressions_chart_options,
            ...{
              xaxis: {
                type: "datetime",
                categories:
                  this.$store.state.facebook.facebook_time_based_campaign_insights.map(
                    (date) =>
                      new Date(date.date_stop).toLocaleDateString("en-US")
                  ),
                labels: {
                  format: "MMM dd",
                },
              },
            },
          });
      } catch (error) {
        this.error = error;
      }

      //Clicks CTR Graph Data
      try {
        this.clicks_chart_options.series = [
          {
            name: "Clicks",
            data: this.$store.state.facebook.facebook_time_based_campaign_insights.map(
              (clicks) => parseInt(clicks.clicks)
            ),
          },
          {
            name: "CTR",
            data: this.$store.state.facebook.facebook_time_based_campaign_insights.map(
              (ctr) => ctr.ctr * 1000
            ),
          },
        ];

        this.clicks_chart_options = {
          ...this.clicks_chart_options,
          ...{
            xaxis: {
              type: "datetime",
              categories:
                this.$store.state.facebook.facebook_time_based_campaign_insights.map(
                  (date) => new Date(date.date_stop).toLocaleDateString("en-US")
                ),
            },
          },
        };
      } catch (error) {
        this.error = error;
      }
    },
    async deleteFBCampaign(id) {
      const combined_data = {
        campaign_id: id,
        ad_account_id: this.adAccountId,
      };

      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch(
          "facebook/deleteFacebookCampaign",
          combined_data
        );
        this.$store.commit("makingApiRequest", false);
        this.$notify({
          title: "Campaign deleted successfully!",
          group: "success",
        });
        this.$router.push("/facebook/campaigns");
      } catch (error) {
        this.$notify({
          title: error,
          group: "errors",
        });
        this.$store.commit("makingApiRequest", false);
        this.dialog = !this.dialog;
        this.error = error;
      }
    },
    async pauseFacebookCampaign() {
      let body = {
        facebook_body: {
          status: "PAUSED",
        },
      };

      let combined_data = {
        ad_account_id: this.adAccountId,
        campaign_id: this.$route.params.id,
        body: body,
      };
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch(
          "facebook/updateFacebookCampaign",
          combined_data
        );
        this.$store.commit("makingApiRequest", false);
        this.$notify({
          title: "Campaign Paused successfully!",
          group: "success",
        });
        await this.$router.push({
          name: "facebook-campaign",
          params: { id: this.campaignId },
        });
      } catch (error) {
        this.$notify({
          title: error,
          group: "errors",
        });
        this.$store.commit("makingApiRequest", false);
      }
    },
    async activateFacebookCampaign() {
      let body = {
        facebook_body: {
          status: "ACTIVE",
        },
      };

      let combined_data = {
        ad_account_id: this.adAccountId,
        campaign_id: this.$route.params.id,
        body: body,
      };
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch(
          "facebook/updateFacebookCampaign",
          combined_data
        );
        this.$notify({
          title: "Campaign Activated successfully!",
          group: "success",
        });
        await this.$router.push({
          name: "facebook-campaign",
          params: { id: this.campaignId },
        });
        this.$store.commit("makingApiRequest", false);
      } catch (error) {
        this.$notify({
          title: error,
          group: "errors",
        });
      }
    },
    percentageColor(status) {
      if (status === "up") return "dashboard-green ml-2";
      if (status === "down") return "dashboard-red ml-2";
    },
    displaySideWidget(adSet) {
      this.sideWidgetData = this.facebook_adsets.find(
        (adset) => adset.id == adSet.id
      );
    },
    selectSeries() {
      this.updateAgeGenderChart();
    },
    selectBreakdowns() {
      this.updateBreakdownChart();
    },
    updateAgeGenderChart() {
      if (this.facebook_combined_campaign_insights) {
        if (this.select_series === "All") {
          if (this.select_metrics === "Impressions") {
            this.ageGenderOptions.series = [];
            this.ageGenderOptions.yaxis.title.text = "Impressions";
            this.genderGroups.forEach((group) => {
              const groupName = group.charAt(0).toUpperCase() + group.slice(1);
              this.ageGenderOptions.series.push({
                name: groupName,
                data: [],
              });
            });
            this.prepareBreakdownsChartData()["impressions"][
              "age_gender"
            ].forEach((item) => {
              this.genderGroups.forEach((group) => {
                const impressions = item.data.find(
                  (d) => d.group === group
                ).impressions;
                this.ageGenderOptions.series
                  .find(
                    (s) =>
                      s.name === group.charAt(0).toUpperCase() + group.slice(1)
                  )
                  .data.push(impressions);
              });
            });
            this.$nextTick(() => {
              const chart = this.$refs.ageGenderChart;
              if (chart && typeof chart.updateSeries === "function") {
                chart.updateSeries(this.ageGenderOptions.series);
                chart.updateOptions({
                  yaxis: this.ageGenderOptions.yaxis,
                  xaxis: {
                    categories: this.prepareBreakdownsChartData()[
                      "impressions"
                    ]["age_gender"].map((item) => item.ageRange),
                  },
                });
              }
            });
          }

          if (this.select_metrics === "Reach") {
            this.ageGenderOptions.series = [];
            this.ageGenderOptions.yaxis.title.text = "Reach";
            this.genderGroups.forEach((group) => {
              const groupName = group.charAt(0).toUpperCase() + group.slice(1);
              this.ageGenderOptions.series.push({
                name: groupName,
                data: [],
              });
            });
            this.prepareBreakdownsChartData()["reach"]["age_gender"].forEach(
              (item) => {
                this.genderGroups.forEach((group) => {
                  const reach = item.data.find((d) => d.group === group).reach;
                  this.ageGenderOptions.series
                    .find(
                      (s) =>
                        s.name ===
                        group.charAt(0).toUpperCase() + group.slice(1)
                    )
                    .data.push(reach);
                });
              }
            );
            this.$nextTick(() => {
              const chart = this.$refs.ageGenderChart;
              if (chart && typeof chart.updateSeries === "function") {
                chart.updateSeries(this.ageGenderOptions.series);
                chart.updateOptions({
                  yaxis: this.ageGenderOptions.yaxis,
                  xaxis: {
                    categories: this.prepareBreakdownsChartData()["reach"][
                      "age_gender"
                    ].map((item) => item.ageRange),
                  },
                });
              }
            });
          }
          if (this.select_metrics === "Spend") {
            this.ageGenderOptions.series = [];
            this.ageGenderOptions.yaxis.title.text = "Spend ($)";
            this.genderGroups.forEach((group) => {
              const groupName = group.charAt(0).toUpperCase() + group.slice(1);
              this.ageGenderOptions.series.push({
                name: groupName,
                data: [],
              });
            });
            this.prepareBreakdownsChartData()["spend"]["age_gender"].forEach(
              (item) => {
                this.genderGroups.forEach((group) => {
                  const spend = item.data.find((d) => d.group === group).spend;
                  this.ageGenderOptions.series
                    .find(
                      (s) =>
                        s.name ===
                        group.charAt(0).toUpperCase() + group.slice(1)
                    )
                    .data.push(spend);
                });
              }
            );
            this.$nextTick(() => {
              const chart = this.$refs.ageGenderChart;
              if (chart && typeof chart.updateSeries === "function") {
                chart.updateSeries(this.ageGenderOptions.series);
                chart.updateOptions({
                  yaxis: this.ageGenderOptions.yaxis,
                  xaxis: {
                    categories: this.prepareBreakdownsChartData()["spend"][
                      "age_gender"
                    ].map((item) => item.ageRange),
                  },
                });
              }
            });
          }
        }
        if (this.select_series === "Male") {
          if (this.select_metrics === "Impressions") {
            this.ageGenderOptions.series = [];
            let maleImpressions = [];
            let ageGenderData =
              this.prepareBreakdownsChartData()["impressions"]["age_gender"];
            Object.values(ageGenderData).forEach((item) => {
              const male = item.data.find((i) => i.group === "male");
              if (male) {
                maleImpressions.push(parseInt(male.impressions) || 0);
              }
            });
            this.$nextTick(() => {
              this.$refs.ageGenderChart.updateSeries([
                { name: "Impressions", data: maleImpressions },
              ]);
              this.$refs.ageGenderChart.updateOptions([
                { yaxis: { title: { text: "Impressions" } } },
              ]);
            });
          }
          if (this.select_metrics === "Reach") {
            this.ageGenderOptions.series = [];
            let maleReach = [];
            let ageGenderData =
              this.prepareBreakdownsChartData()["reach"]["age_gender"];
            Object.values(ageGenderData).forEach((item) => {
              const male = item.data.find((i) => i.group === "male");
              if (male) {
                maleReach.push(parseInt(male.reach) || 0);
              }
            });
            this.$nextTick(() => {
              this.$refs.ageGenderChart.updateSeries([
                { name: "Reach", data: maleReach },
              ]);
              this.$refs.ageGenderChart.updateOptions([
                { yaxis: { title: { text: "Reach" } } },
              ]);
            });
          }
          if (this.select_metrics === "Spend") {
            this.ageGenderOptions.series = [];
            let maleSpend = [];
            let ageGenderData =
              this.prepareBreakdownsChartData()["spend"]["age_gender"];
            Object.values(ageGenderData).forEach((item) => {
              const male = item.data.find((i) => i.group === "male");
              if (male) {
                maleSpend.push(parseInt(male.reach) || 0);
              }
            });
            this.$nextTick(() => {
              this.$refs.ageGenderChart.updateSeries([
                { name: "Spend", data: maleSpend },
              ]);
              this.$refs.ageGenderChart.updateOptions([
                { yaxis: { title: { text: "Spend" } } },
              ]);
            });
          }
        }
        if (this.select_series === "Female") {
          if (this.select_metrics === "Impressions") {
            this.ageGenderOptions.series = [];
            let femaleImpressions = [];
            let ageGenderData =
              this.prepareBreakdownsChartData()["impressions"]["age_gender"];
            Object.values(ageGenderData).forEach((item) => {
              const female = item.data.find((i) => i.group === "female");
              if (female) {
                femaleImpressions.push(parseInt(female.impressions) || 0);
              }
            });
            this.$nextTick(() => {
              this.$refs.ageGenderChart.updateSeries([
                { name: "Impressions", data: femaleImpressions },
              ]);
              this.$refs.ageGenderChart.updateOptions([
                { yaxis: { title: { text: "Impressions" } } },
              ]);
            });
          }
          if (this.select_metrics === "Reach") {
            this.ageGenderOptions.series = [];
            let femaleReach = [];
            let ageGenderData =
              this.prepareBreakdownsChartData()["reach"]["age_gender"];
            Object.values(ageGenderData).forEach((item) => {
              const female = item.data.find((i) => i.group === "female");
              if (female) {
                femaleReach.push(parseInt(female.reach) || 0);
              }
            });
            this.$nextTick(() => {
              this.$refs.ageGenderChart.updateSeries([
                { name: "Reach", data: femaleReach },
              ]);
              this.$refs.ageGenderChart.updateOptions([
                { yaxis: { title: { text: "Reach" } } },
              ]);
            });
          }
          if (this.select_metrics === "Spend") {
            this.ageGenderOptions.series = [];
            let femaleSpend = [];
            let ageGenderData =
              this.prepareBreakdownsChartData()["spend"]["age_gender"];
            Object.values(ageGenderData).forEach((item) => {
              const female = item.data.find((i) => i.group === "female");
              if (female) {
                femaleSpend.push(parseInt(female.spend) || 0);
              }
            });
            this.$nextTick(() => {
              this.$refs.ageGenderChart.updateSeries([
                { name: "Spend", data: femaleSpend },
              ]);
              this.$refs.ageGenderChart.updateOptions([
                { yaxis: { title: { text: "Spend ($)" } } },
              ]);
            });
          }
        }
      }
    },
    updateDoubleChart(chartRef, comparison, unit, series1, series2) {
      this.$nextTick(() => {
        const chart = chartRef;
        if (chart && typeof chart.updateSeries === "function") {
          chart.updateSeries(
            {
              name: this.formatString(series1),
              data: this.prepareBreakdownsChartData()[comparison][unit].map(
                (item) => item[series1.replace(/^'(.*)'$/, "$1")]
              ),
            },
            {
              name: this.formatString(series2),
              data: this.prepareBreakdownsChartData()[comparison][unit].map(
                (item) => item[series2.replace(/^'(.*)'$/, "$1")]
              ),
            }
          );
          if (unit === "country") {
            chart.updateOptions({
              xaxis: {
                categories: this.formatCountry(
                  this.prepareBreakdownsChartData()[comparison][unit].map(
                    (item) => this.formatString(item.group)
                  )
                ),
              },
            });
          } else {
            chart.updateOptions({
              xaxis: {
                categories: this.prepareBreakdownsChartData()[comparison][
                  unit
                ].map((item) => this.formatString(item.group)),
              },
            });
          }
        }
      });
    },
    updateBreakdownChart() {
      if (this.facebook_combined_campaign_insights) {
        if (this.select_breakdowns === "Country") {
          this.$refs.chart.updateSeries(
            Object.values(
              this.facebook_combined_campaign_insights.engagement_rates.country
            )
          );
          this.$refs.chart.updateOptions({
            labels: this.formatCountry(
              Object.keys(
                this.facebook_combined_campaign_insights.engagement_rates
                  .country
              ).map(this.formatString)
            ),
          });
          this.updateDoubleChart(
            this.$refs.doublebarchart,
            "impressionsActionsData",
            "country",
            "impressions",
            "actions"
          );
        }
        if (this.select_breakdowns === "Region") {
          this.$refs.chart.updateSeries(
            Object.values(
              this.facebook_combined_campaign_insights.engagement_rates.region
            )
          );
          this.$refs.chart.updateOptions({
            labels: Object.keys(
              this.facebook_combined_campaign_insights.engagement_rates.region
            ).map(this.formatString),
          });
          this.updateDoubleChart(
            this.$refs.doublebarchart,
            "impressionsActionsData",
            "region",
            "impressions",
            "actions"
          );
        }
        if (this.select_breakdowns === "Publisher Platform") {
          this.$refs.chart.updateSeries(
            Object.values(
              this.facebook_combined_campaign_insights.engagement_rates
                .publisher_platform
            )
          );
          this.$refs.chart.updateOptions({
            labels: Object.keys(
              this.facebook_combined_campaign_insights.engagement_rates
                .publisher_platform
            ).map(this.formatString),
          });
          this.updateDoubleChart(
            this.$refs.doublebarchart,
            "impressionsActionsData",
            "publisher_platform",
            "impressions",
            "actions"
          );
        }
        if (this.select_breakdowns === "Impression Device") {
          this.$refs.chart.updateSeries(
            Object.values(
              this.facebook_combined_campaign_insights.engagement_rates
                .impression_device
            )
          );
          this.$refs.chart.updateOptions({
            labels: Object.keys(
              this.facebook_combined_campaign_insights.engagement_rates
                .impression_device
            ).map(this.formatString),
          });
          this.updateDoubleChart(
            this.$refs.doublebarchart,
            "impressionsActionsData",
            "impression_device",
            "impressions",
            "actions"
          );
        }
      }
    },
    formatString(str) {
      return str
        .split("_")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    },
    prepareBreakdownsChartData() {
      let preparedData = [];
      let impressionsActionsData = [];
      let reachActionsData = [];
      let spendActionsData = [];
      let impressionsMetrics = [];
      let reachMetrics = [];
      let spendMetrics = [];

      if (this.facebook_combined_campaign_insights) {
        //Impressions/Actions
        [this.facebook_combined_campaign_insights].forEach((item) =>
          Object.keys(item.impressions).forEach((key) => {
            const value = item.impressions[key];
            impressionsActionsData[key] = [];
            Object.keys(value).forEach((i) => {
              const actions = item.actions;
              let sumActions = 0;
              Object.keys(actions).forEach((item) => {
                for (let j in actions[item]) {
                  if (i in actions[item][j]) {
                    if (actions[item][j][i] != 0) {
                      sumActions += Number(actions[item][j][i]);
                    }
                  }
                }
              });
              impressionsActionsData[key].push({
                group: i,
                impressions: Number(value[i]),
                actions: sumActions,
              });
            });
          })
        );

        //Impressions
        [this.facebook_combined_campaign_insights].forEach((item) => {
          Object.keys(item.impressions).forEach((key) => {
            if (key === "age_gender") {
              let ageRanges = Object.keys(item.impressions[key]);
              const genders = ["male", "female", "unknown"];
              const ageGenderImpressions = [];

              ageRanges.forEach((ageRange) => {
                const ageGender = item.impressions[key][ageRange];
                const ageGenderValues = [];

                genders.forEach((gender) => {
                  ageGenderValues.push({
                    group: gender,
                    impressions: parseInt(ageGender[gender]),
                  });
                });
                ageGenderImpressions.push({
                  ageRange: ageRange,
                  data: ageGenderValues,
                });
              });
              if (!impressionsMetrics[key]) {
                impressionsMetrics[key] = ageGenderImpressions;
              } else {
                impressionsMetrics[key].push(...ageGenderImpressions);
              }
            } else {
              const impressionsValue = item.impressions[key];
              impressionsMetrics[key] = Object.keys(impressionsValue).map(
                (i) => ({
                  group: i,
                  impressions: Number(impressionsValue[i]),
                })
              );
            }
          });
        });

        //Reach
        [this.facebook_combined_campaign_insights].forEach((item) => {
          Object.keys(item.reach).forEach((key) => {
            if (key === "age_gender") {
              let ageRanges = Object.keys(item.impressions[key]);
              const genders = ["male", "female", "unknown"];
              const ageGenderReach = [];
              ageRanges.forEach((ageRange) => {
                const ageGender = item.reach[key][ageRange];
                const ageGenderValues = [];
                genders.forEach((gender) => {
                  ageGenderValues.push({
                    group: gender,
                    reach: parseInt(ageGender[gender]),
                  });
                });
                ageGenderReach.push({
                  ageRange: ageRange,
                  data: ageGenderValues,
                });
              });
              if (!reachMetrics[key]) {
                reachMetrics[key] = ageGenderReach;
              } else {
                reachMetrics[key].push(...ageGenderReach);
              }
            } else {
              const reachValue = item.reach[key];
              reachMetrics[key] = Object.keys(reachValue).map((i) => ({
                group: i,
                reach: Number(reachValue[i]),
              }));
            }
          });
        });

        //Spend
        [this.facebook_combined_campaign_insights].forEach((item) => {
          Object.keys(item.spend).forEach((key) => {
            if (key === "age_gender") {
              let ageRanges = Object.keys(item.impressions[key]);
              const genders = ["male", "female", "unknown"];
              const ageGenderSpend = [];
              ageRanges.forEach((ageRange) => {
                const ageGender = item.spend[key][ageRange];
                const ageGenderValues = [];
                genders.forEach((gender) => {
                  ageGenderValues.push({
                    group: gender,
                    spend: parseInt(ageGender[gender]),
                  });
                });
                ageGenderSpend.push({
                  ageRange: ageRange,
                  data: ageGenderValues,
                });
              });
              if (!spendMetrics[key]) {
                spendMetrics[key] = ageGenderSpend;
              } else {
                spendMetrics[key].push(...ageGenderSpend);
              }
            } else {
              const spendValue = item.spend[key];
              spendMetrics[key] = Object.keys(spendValue).map((i) => ({
                group: i,
                spend: Number(spendValue[i]),
              }));
            }
          });
        });

        let actions = this.facebook_combined_campaign_insights.actions;
        let actionArray = [];
        for (let action in actions) {
          let value = 0;
          for (let unit in actions[action]) {
            for (let key in actions[action][unit]) {
              const parsedValue = parseInt(actions[action][unit][key], 10) || 0;
              value += parsedValue;
            }
          }
          actionArray.push({ action, value });
          this.actions = actionArray;
        }

        let sortedActions = this.actions.sort((a, b) => b.value - a.value);

        this.bestActions = sortedActions.slice(0, 5);
        this.leastActions = sortedActions.slice(-5);

        preparedData["impressionsActionsData"] = impressionsActionsData;
        preparedData["reachActionsData"] = reachActionsData;
        preparedData["spendActionsData"] = spendActionsData;
        preparedData["impressions"] = impressionsMetrics;
        preparedData["reach"] = reachMetrics;
        preparedData["spend"] = spendMetrics;
        preparedData["actions"] = actionArray;
      }

      return preparedData;
    },
    formatCountry(country_values) {
      let countries_found = country_values.map((i) =>
        this.countries.find((country) => country["ISO3166-1-Alpha-2"] === i)
      );
      return countries_found.map((i) => i["CLDR display name"]);
    },
    getDonutChartInitialData() {
      if (this.facebook_combined_campaign_insights) {
        // Update series
        this.donutChartOptions.series = Object.values(
          this.facebook_combined_campaign_insights.engagement_rates.country
        );

        // Update labels
        this.donutChartOptions = {
          ...this.donutChartOptions,
          ...{
            labels: this.formatCountry(
              Object.keys(
                this.facebook_combined_campaign_insights.engagement_rates
                  .country
              ).map(this.formatString)
            ),
          },
        };
      }
    },
    getPieChartInitialData() {
      if (this.facebook_combined_campaign_insights) {
        this.pieChartOptions.series = Object.values(
          this.facebook_combined_campaign_insights.engagement_rates
            .action_device
        );

        this.pieChartOptions = {
          ...this.pieChartOptions,
          ...{
            labels: Object.keys(
              this.facebook_combined_campaign_insights.engagement_rates
                .action_device
            ).map(this.formatString),
          },
        };
      }
    },

    getDoublebarChartInitial() {
      if (this.facebook_combined_campaign_insights) {
        const impressionsActionsData =
          this.prepareBreakdownsChartData()["impressionsActionsData"][
            "country"
          ];

        // Update series property
        this.doublebarchartOptions.series = [
          {
            name: "Impressions",
            data: impressionsActionsData.map((item) => item.impressions),
          },
          {
            name: "Actions",
            data: impressionsActionsData.map((item) => item.actions),
          },
        ];

        this.doublebarchartOptions.options = {
          ...this.doublebarchartOptions.options,
          ...{
            xaxis: {
              categories: this.formatCountry(
                impressionsActionsData.map((item) =>
                  this.formatString(item.group)
                )
              ),
            },
            yaxis: {
              title: {
                text: "Impressions|Actions",
              },
            },
          },
        };

        // // Update xaxis and yaxis properties under options
        // this.doublebarchartOptions.options.xaxis.categories = this.formatCountry(impressionsActionsData.map((item) => this.formatString(item.group)));
        // this.doublebarchartOptions.options.yaxis.title.text = "Impressions|Actions";
      }
    },

    getGenderAgeGraphInitialData() {
      if (this.facebook_combined_campaign_insights) {
        //GenderAge Graph
        this.genderGroups.forEach((group) => {
          const groupName = group.charAt(0).toUpperCase() + group.slice(1);
          this.ageGenderOptions.series.push({
            name: groupName,
            data: [],
          });
        });
        this.prepareBreakdownsChartData()["impressions"]["age_gender"].forEach(
          (item) => {
            this.genderGroups.forEach((group) => {
              const impressions = item.data.find(
                (d) => d.group === group
              ).impressions;
              this.ageGenderOptions.series
                .find(
                  (s) =>
                    s.name === group.charAt(0).toUpperCase() + group.slice(1)
                )
                .data.push(impressions);
            });
          }
        );

        this.ageGenderOptions = {
          ...this.ageGenderOptions,
          ...{
            xaxis: {
              categories: this.prepareBreakdownsChartData()["impressions"][
                "age_gender"
              ].map((item) => item.ageRange),
            },
          },
        };
      }
    },

    selectDateRange() {
      // reverses dates if user selects dates in reverse order
      if (this.dates.at(-1) < this.dates.at(0)) {
        this.dates = this.dates.reverse();
      }
      this.$refs.impressionschart.zoomX(
        new Date(this.dates.at(0)).getTime(),
        new Date(this.dates.at(-1)).getTime()
      );
      this.$refs.clickchart.zoomX(
        new Date(this.dates.at(0)).getTime(),
        new Date(this.dates.at(-1)).getTime()
      );

      this.facebook_time_based_campaign_insights.filter((item) => {
        const itemDate = new Date(item.date_stop);
        const startDate = new Date(this.dates[0]);
        const endDate = new Date(this.dates[1]);
        return itemDate >= startDate && itemDate <= endDate;
      });
    },

    async readClients() {
      try {
        await this.$store.dispatch("readClients");
      } catch (error) {
        this.error = error;
      }
    },
  },
  computed: {
    superAdmin() {
      return this.$store.getters.superAdmin;
    },
    ad_account_id() {
      return this.$store.state.facebook.ad_account_id;
    },
    facebook_campaign() {
      return this.$store.state.facebook.facebook_campaign;
    },
    facebook_campaign_insights() {
      return this.$store.state.facebook.facebook_campaign_insights;
    },
    facebook_combined_campaign_insights() {
      return this.$store.state.facebook.facebook_combined_campaign_insights;
    },
    facebook_adsets() {
      return this.$store.state.facebook.facebook_adsets_by_campaign;
    },
    facebook_age_based_campaign_insights() {
      return this.$store.state.facebook.facebook_age_based_campaign_insights;
    },
    facebook_time_based_campaign_insights() {
      return this.$store.state.facebook.facebook_time_based_campaign_insights;
    },
    facebook_campaign_adsets() {
      return this.$store.state.facebook.facebook_adsets_by_campaign;
    },
    // eslint-disable-next-line vue/return-in-computed-property
    currency() {
      let data = this.$store.state.facebook.ad_account;
      if (data) {
        return data.currency;
      }
    },
    facebook_countries() {
      return this.$store.state.facebook.facebook_combined_campaign_insights
        ? Object.entries(
            this.$store.state.facebook.facebook_combined_campaign_insights
              .impressions.country
          ).map(([code, value]) => ({
            country: code,
            imp: parseInt(value),
          }))
        : [];
    },
    countries() {
      return this.$store.state.dsp.static_dsp_data.countries_v2;
    },
    dateRangeText() {
      return this.dates.join("  -  ");
    },
    clients() {
      return this.$store.state.clients;
    },
    loading() {
      return this.$store.state.isLoading;
    },
  },
  async beforeMount() {
    let payload = {};
    payload = {};
    payload.facebook_adsets_by_campaign = null;
    await this.$store.commit("facebook/setFacebookAdsetsByCampaign", payload);
  },
};
</script>

<style scoped>
.min-height {
  min-height: 310px;
}

#basemap_id {
  height: 500px;
}

.general-card {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.chart-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-skeleton {
  width: 100%;
  height: 400px;
  /* background-color: #d2cfcf; */
  animation: fadeIn 1s linear infinite alternate;
}

.select-boxes {
  width: 100%;
  height: 50px;
  border-radius: 25px;
  /* background-color: #d2cfcf; */
  animation: fadeIn 1s linear infinite alternate;
}

.heading {
  width: 20%;
  height: 50px;
  border-radius: 25px;
  /* background-color: #d2cfcf; */
  animation: fadeIn 1s linear infinite alternate;
}

.button {
  width: 10%;
  height: 50px;
  border-radius: 25px;
  /* background-color: #d2cfcf; */
  animation: fadeIn 1s linear infinite alternate;
}

.actions {
  width: 100%;
  height: 100%;
  border-radius: 25px;
  /* background-color: #d2cfcf; */
  animation: fadeIn 1s linear infinite alternate;
}

@keyframes fadeIn {
  0% {
    background-color: hsl(200, 20%, 80%);
  }

  100% {
    background-color: hsl(200, 20%, 95%);
  }
}
</style>
