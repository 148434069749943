<template>
  <div>
    <v-data-table v-if="adsets" :headers="headers" :items="adsets" @click:row="viewAdset">
      <!-- eslint-disable-next-line -->
      <template #[`item.name`]="{ item }">
        <span class="font-weight-bold">
          <router-link
            :to="{
              name: 'facebook-ad-set',
              params: {
                id: item.id,
              },
            }"
          >
            {{ item.name }}
          </router-link>
        </span>
      </template>

      <!-- eslint-disable-next-line -->
      <template #[`item.lifetime_budget`]="{ item }">
        <span class="text-capitalize">{{
          item.lifetime_budget ? item.lifetime_budget : "-"
        }}</span>
      </template>

      <!-- eslint-disable-next-line -->
      <template #[`item.remaining_budget`]="{ item }">
        <span class="text-capitalize">{{
          item.budget_remaining ? item.budget_remaining : "-"
        }}</span>
      </template>

      <!-- eslint-disable-next-line -->
      <template #[`item.daily_budget`]="{ item }">
        <span class="text-capitalize">{{
          item.daily_budget ? item.daily_budget : "-"
        }}</span>
      </template>

      <!-- eslint-disable-next-line -->
      <template #[`item.status`]="{ item }">
        <span class="text-capitalize">{{
          item.status ? formatString(item.status) : "-"
        }}</span>
      </template>

      <!-- eslint-disable-next-line -->
      <template #[`item.optimization`]="{ item }">
        <span class="text-capitalize">{{
          item.optimization_goal ? formatString(item.optimization_goal) : "-"
        }}</span>
      </template>

      <!-- eslint-disable-next-line -->
      <template #[`item.start`]="{ item }">
        <span class="text-capitalize">{{
          item.start_time ? formatDate(item.start_time) : "-"
        }}</span>
      </template>

      <!-- eslint-disable-next-line -->
      <template #[`item.end`]="{ item }">
        <span class="text-capitalize">{{
          item.end_time ? formatDate(item.end_time) : "-"
        }}</span>
      </template>
    </v-data-table>
  </div>
</template>
<script>
export default {
  data() {
    return {
      headers: [
        {
          text: "Name",
          align: "start",
          value: "name",
          width: "20%",
        },
        { text: "Lifetime Budget", value: "lifetime_budget" },
        { text: "Daily Budget", value: "daily_budget" },
        { text: "Budget Remaining", value: "remaining_budget" },
        { text: "Status", value: "status" },
        { text: "Optimization Goal", value: "optimization" },
        { text: "Start Date", value: "start" },
        { text: "End Date", value: "end" },
      ],
    };
  },
  props: {
    adsets: {
      type: Array,
    },
  },
  methods: {
    viewAdset() {},
    formatDate(date) {
      return new Date(date).toLocaleString("en-us", {
        year: "numeric",
        month: "short",
        day: "numeric",
      });
    },
    formatString(name) {
        return name
    .toLowerCase()
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
    },
  },
};
</script>
